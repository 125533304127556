<template>
  <div class="partner-assistant">
    <how-to-transaction
      @closeModal="changeHowToTransactionModalVisibility(false)"
      v-if="isHowToTransactionModalOpen"
    />
    <div class="partner-assistant__container">
      <div class="partner-assistant__content assistant-content">
        <div class="assistant-content__title">
          {{ $t("referral_partner.no_link.assistant.title") }}
        </div>
        <div
          v-if="currentStepData"
          class="assistant-content__action content-action"
        >
          <div class="content-action__text">
            {{ $t(currentStepData.title) }}
          </div>
          <div class="content-action__button">
            <primary-colored-button @click="currentStepData.handler">
              {{ $t(currentStepData.action) }}
            </primary-colored-button>
          </div>
        </div>
      </div>
      <div class="partner-assistant__logo">
        <img
          :src="currentLogo"
          :alt="$t('referral_partner.no_link.assistant.title')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";
import HowToTransaction from "@/components/referral/Popup/HowToTransactionModal";
import assistantLogo from "@/assets/img/referrals/partner/assistant-logo.svg";
import assistantLogoMob from "@/assets/img/referrals/partner/assistant-logo_mob.svg";
import responsiveMixin from "@/mixins/responsive-mixin";
import loadReferralsTasksMixin from "@/mixins/load-referrals-tasks.mixin";

export default {
  name: "ReferralPartnerAssistant",
  components: { PrimaryColoredButton, HowToTransaction },
  mixins: [responsiveMixin, loadReferralsTasksMixin],
  data() {
    return {
      assistantLogo,
      assistantLogoMob,
      assistantSteps: [
        {
          referral_task_slug: "registration-complete",
          title: "",
          action: "",
          handler: () => {},
        },
        {
          referral_task_slug: "card-created",
          title: "referral_partner.no_link.assistant.steps.open_card.title",
          action: "referral_partner.no_link.assistant.steps.open_card.action",
          handler: this.openCard,
        },
        {
          referral_task_slug: "wallet-added",
          title:
            "referral_partner.no_link.assistant.steps.connect_exchange.title",
          action:
            "referral_partner.no_link.assistant.steps.connect_exchange.action",
          handler: this.connectExchange,
        },
        {
          referral_task_slug: "transaction-complete",
          title: "referral_partner.no_link.assistant.steps.transaction.title",
          action: "referral_partner.no_link.assistant.steps.transaction.action",
          handler: this.makeTransaction,
        },
      ],
      isHowToTransactionModalOpen: false,
    };
  },
  async created() {
    await this.loadReferralTasks();
  },
  computed: {
    currentStepData() {
      let _activeStep = this.findActiveStep();

      if (!_activeStep) {
        return this.assistantSteps[0];
      }

      return this.assistantSteps.find(
        (_aS) => _aS.referral_task_slug === _activeStep.referral_task_slug || ""
      );
    },
    currentLogo() {
      return this.isMobile ? this.assistantLogoMob : this.assistantLogo;
    },
  },
  methods: {
    findActiveStep() {
      let _currentTaskIndex = [...this.referralTasks]
        .sort((t1, t2) => (t1.ord > t2.ord ? 1 : t1.ord === t2.ord ? 0 : -1))
        .findIndex((t) => t.status === "new");

      _currentTaskIndex =
        _currentTaskIndex > -1
          ? _currentTaskIndex
          : this.referralTasks.length - 1;

      return this.referralTasks[_currentTaskIndex];
    },
    openCard() {
      this.$router.push({
        path: "/cards",
        query: {
          start_add_card: true,
        },
      });
    },
    connectExchange() {
      this.$router.push({
        path: "/wallets",
        query: {
          add_wallet: true,
        },
      });
    },
    makeTransaction() {
      this.changeHowToTransactionModalVisibility(true);
    },
    changeHowToTransactionModalVisibility(value) {
      this.isHowToTransactionModalOpen = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.partner-assistant {
  --block-gap: 2.5rem;

  &__container {
    padding: 2.5rem;
    background: #ffffff;
    border-radius: 1.5rem;

    display: grid;
    grid-template-columns: 35% 1fr;
    column-gap: calc(var(--block-gap) * 2);
  }

  &__content {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: calc(100% + var(--block-gap));
      top: 0;
      background-color: $dividersGray;
      width: 1px;
      height: 100%;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.assistant-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $mainBlack;

    margin-bottom: 2.5rem;
  }
}

.content-action {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1.5rem;

  &__text {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
  }
}

@media screen and (max-width: 486px) {
  .partner-assistant {
    &__container {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      row-gap: calc(var(--block-gap) * 2);
      padding: 2.5rem 1.5rem;
    }

    &__content {
      &:before {
        left: 0;
        top: calc(100% + var(--block-gap));
        background-color: $dividersGray;
        width: 100%;
        height: 1px;
      }
    }
  }
  .assistant-content {
    &__title {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .content-action {
    &__text {
    }
  }
}
</style>
